  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
  }

   .preloader::before {
    -webkit-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    transform: translateX(-101%);
}
 .preloader::after {
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%) ;
}
  .fadeInLeft {
    /* -webkit-animation-name: fadeInLeft;
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 0ms;
    animation-name: fadeInLeft; */
    visibility: visible;
    animation-duration: 2000ms;
    animation-delay: 0ms;
    animation-name: fadeInLeft;
  }
/* footer css */
  .info ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  .info li {
    padding: 0px;
    margin: 0px;
  }
  .phoneNo{
    color: #868da5;
      text-decoration: none;
  }
  .playBtnCircle{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -35px 0px 0px -35px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #061138;
    font-size: 24px;
    background: #ffffff;
    border-radius: 50%;
  }
  .drop-down-d{
    padding: 20px !important; 
    background-color: #000 !important;
    color: #fff !important;
    margin: 20px !important;
  }
  .drop-down-d:hover{
    
    background-color: #d5ae82 !important;
    
  }
  .video-link .overlink:hover {
    background: rgba(0,0,0,0.50) !important;
}
  .phoneNo:hover{
    color: #d5ae82;
    text-decoration: underline;
  }

  @keyframes slideInLeft {
    0% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    visibility: visible;
    animation-duration: 2500ms;
    animation-delay: 0ms;
    animation-name: slideInLeft;
  }
  .info ul li{
    position: relative;
      line-height: 24px;
      color: #868da5;
      font-size: 14px;
      padding-left: 25px;
      margin-bottom: 7px;
  }
  .custom-select-box option:hover {
    background-color: black; /* Set your desired background color on hover */
    color: white; /* Set text color to improve readability */
}
.widgets-inner {
  position: relative;
  padding: 95px 0px 0px;
  padding-left: 400px;
}
.inneRBox {
    position: relative;
    display: block;
    background: #ffffff;
}
 .widgets-inner .shape-1 {
  position: absolute;
  right: 100%;
  top: 0;
  width: 3000px;
  height: 100px;
  background: #ffffff;
  z-index: 1;
}
 .widgets-inner .left-image img {
  position: relative;
  display: inline-block;
  max-width: 100%;
    height: auto;
}
.offer-block h4 {
  position: relative;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
    
}
 .offer-carousel-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 340px;
  text-align: center;
  z-index: 5;
}
.btnBookNow3:hover {
  background-color: #fff !important;
  color: #d5ae82 !important; 
}
.footer-bottom {
  background: #e8ebef;
}
.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}
 
.footer-bottom a {
  color: #868da5;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  text-decoration: none;
  cursor: pointer;
}
 .footer-bottom .bottom-links {
  position: relative;
  float: right;
  padding: 10px 0px;
  color: #7f8a99;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
}
.footer-bottom .copyright {
   color: #7f8a99;
  position: relative;
  float: left;
  position: relative;
  line-height: 24px;
  padding: 12px 0px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
  padding: 10px 0px;
}
 .footer-bottom .inner {
  position: relative;
  padding-left: 400px;
  display: block;
}
 .footer-bottom {
  position: relative;
  line-height: 24px;
  padding: 12px 0px;
  text-transform: capitalize;
  border-top: 1px solid rgba(255,255,255,0.10);
}
.offer-block .offer-price {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  padding-top: 15px;
  background: #ffffff;
  color: #061138;
  text-align: center;
  font-family: 'Playfair Display', serif;
  border-radius: 50%;
  margin: 0 auto 30px;
}
 .offer-block .offer-price .txt {
  display: block;
  line-height: 1.5em;
  font-size: 13px;
  text-transform: uppercase;
  color: #061138;
    text-align: center;
    font-family: 'Playfair Display', serif;
}

 .offer-block .offer-price .percent {
  display: block;
  font-size: 36px;
  line-height: 1.1em;
  color: #061138;
    text-align: center;
    font-family: 'Playfair Display', serif;
}
 .offer-block .subtitle {
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 15px;
}
.offer-block {
  position: relative;
  display: block;
  padding: 40px 40px 30px;
  background: #d5ae82;
  color: #ffffff;
}
 .offer-block {
  position: relative;
  display: block;
  padding: 40px 40px 30px;
  background: #d5ae82;
  color: #ffffff;
}
 .offer-block {
  position: relative;
  display: block;
  padding: 40px 40px 30px;
  background: #d5ae82;
  color: #ffffff;
}
 .widgets-inner .left-image {
  position: absolute;
  right: 100%;
  top: 100px;
  width: 2500px;
  height: auto;
  min-height: 100%;
  background: #061138;
  padding: 50px 20px;
  text-align: right;
  z-index: 1;
}
.widgets-inner .shape-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 340px;
  height: 600px;
  min-height: 100%;
  background: #d5ae82;
  z-index: 2;
}
/* .custom-select-box:hover option,
.custom-select-box:focus option,
.custom-select-box:active option {
  background-color: #fff !important;
  color: #ffde9d !important;
  font-size: 20px !important;
  padding: 10px !important;
} */


.news-block-two {
  position: relative;
  margin-bottom: 50px;
}
.news-block-two .inner-box {
  position: relative;
  display: block;
}
.news-block-two .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}
.news-block-two .image-box img {
  display: block;
  width: 100%;
}

.news-block-two .image-box .link-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6,17,56,0.85);
  color: #ffffff;
  -ms-transform: scaleY(0) translateY(-100px);
  -webkit-transform: scaleY(0) translateY(-100px);
  transform: scaleY(0) translateY(-100px);
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  text-decoration: none;
    outline: none;
}
.newsIcons {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -30px 0px 0px -30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #ffffff;
  color: #d5ae82;
  font-size: 24px;
  border-radius: 50%;
}
.news-block-two .lower-content {
  position: relative;
  padding-left: 90px;
}
.news-block-two .content {
  position: relative;
  padding-top: 25px;
}
.news-block-two .meta-info1 {
  position: relative;
}
.news-block-two .meta-info1 li {
  position: relative;
  float: left;
  font-size: 13px;
  line-height: 24px;
  color: #7f8a99;
  text-transform: uppercase;
  margin-right: 40px;
  margin-bottom: 7px;
  list-style: none;
    padding: 0px;
    margin: 0px;
}
.news-block-two .meta-info1 li a:hover {
  color: #d5ae82;
  text-decoration: underline;
}
.news-block-two h5 a {
  color: #061138;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-decoration: none;
  outline: none;

}
.news-block-two .other-links .add-to-fav {
  position: relative;
  width: 42px;
  height: 42px;
  margin-bottom: 20px;
}
.news-block-two .other-links .add-to-fav a {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  line-height: 40px;
  border: 1px solid rgba(0,0,0,0.10);
  font-size: 18px;
  text-align: center;
  color: rgba(0,0,0,0.50);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-decoration: none;
    cursor: pointer;
}
.news-block-two .other-links .add-comment {
  position: relative;
  width: 42px;
  height: 42px;
  margin-bottom: 20px;
}
.news-block-two .other-links .add-comment a {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  line-height: 40px;
  border: 1px solid rgba(0,0,0,0.10);
  font-size: 18px;
  text-align: center;
  color: rgba(0,0,0,0.50);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-decoration: none;
    cursor: pointer;
}
.news-block-two .other-links .add-to-fav a:hover {
  color: #ffffff;
  background: #d5ae82;
  border-color: #d5ae82;
}
.news-block-two .other-links .add-comment a:hover {
  color: #ffffff;
  background: #d5ae82;
  border-color: #d5ae82;
}
.news-block-two .other-links .share-it a:hover {
  color: #ffffff;
  background: #d5ae82;
  border-color: #d5ae82;
}
.news-block-two .other-links .share-it .share-btn {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  line-height: 40px;
  border: 1px solid rgba(0,0,0,0.10);
  font-size: 18px;
  text-align: center;
  color: rgba(0,0,0,0.50);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  text-decoration: none;
    cursor: pointer;
}
.news-block-two .other-links .share-it {
  position: relative;
  width: 42px;
  height: 42px;
}
.news-block-two .other-links {
  position: absolute;
  left: 0;
  top: 25px;
  width: 60px;
  height: 170px;
  line-height: 46px;
  border-right: 1px solid rgba(0,0,0,0.07);
}
.news-block-two .author img {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.news-block-two .author::before {
  content: '';
  position: absolute;
  left: 0;
  top: -21px;
  width: 50px;
  border-bottom: 1px solid rgba(0,0,0,0.10);
}
.news-block-two .author {
  position: relative;
  line-height: 24px;
  padding-left: 50px;
  min-height: 40px;
  padding-top: 8px;
}
.news-block-two h5 a:hover {
  color: #d5ae82;
  text-decoration: underline;
}
.news-block-two h5 {
  font-size: 20px;
  line-height: 1.4em;
  color: #061138;
  margin-bottom: 42px;
  position: relative;
  font-weight: normal;
  margin: 0px 0px 15px;
  background: none;
  line-height: 1.25em;
  font-family: 'Playfair Display', cursive;
}
.newSFolder{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: #b5c0cf;
  padding-right: 5px;
}
.news-block-two .meta-info1 li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #7f8a99;
  text-decoration: none;
  cursor: pointer;
}
.news-block-two .meta-info1 li:last-child {
  margin-right: 0;
}
.news-block-two .image-box .post-date {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  padding: 6px 5px;
  text-align: center;
  line-height: 24px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Playfair Display', serif;
  z-index: 1;
}
.news-block-two .image-box .post-date .day {
  display: block;
  color: #061138;
  font-size: 24px;
}

.news-block-two .inner-box:hover .image-box .link-layer {
  opacity: 1;
  -ms-transform: scaleY(1) translateY(0px);
  -webkit-transform: scaleY(1) translateY(0px);
  transform: scaleY(1) translateY(0px);
}
.news-block-two .image-box .link-layer .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -30px 0px 0px -30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #ffffff;
  color: #d5ae82;
  font-size: 24px;
  border-radius: 50%;
}
.rentalBlock .imagEBbox {
  position: relative;
  display: block;
  top: 0;
  overflow: hidden;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.rentalBlock .imagEBbox img {
  position: relative;
  display: block;
  width: 100%;
}

.rentalBlock .pricENBox {
  position: absolute;
  right: 0;
  top: 20px;
  width: 100px;
  padding: 5px 15px 7px;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 24px;
  background: #1a1838;
  text-align: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 2;
}
.rentalBlock .inneRBox:hover .pricENBox {
  right: 20px;
  background: #d5ae82;
}
.news-block-two .meta-info1 {
  position: relative;
}
.rentalBlock .inneRBox:hover .imagEBbox {
  top: -38px;
}
.rentalBlock .pricENBox .price {
  position: relative;
  display: block;
  font-size: 18px;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  padding-bottom: 5px;
  line-height: 30px;
  margin-bottom: 3px;
  border-bottom: 1px solid rgba(255,255,255,0.30);
}
.bckIMg{
  position: relative;
  padding: 0px 0px;
  background: #646464;
  color: #ffffff;
  overflow: hidden;
}
.bckIMg .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}
.bckIMg .image-layer.right-layer {
  left: auto;
  right: 0;
}
.bckIMg .shapes {
  position: absolute;
  left: 50%;
  margin-left: -360px;
  top: 0;
  width: 700px;
}
.bckIMg .shapes::before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 0;
  border: 30px solid transparent;
  border-top: 30px solid #ffffff;
  z-index: 1;
}
.bckIMg .shapes .shape {
  position: absolute;
  left: 30px;
  top: 0;
  border: 180px solid transparent;
  border-top: 180px solid #d5ae82;
  opacity: 0.80;
}
.bckIMg .column {
  position: relative;
  float: left;
  width: 50%;
}
.bckIMg .column .image-layer {
  width: 100%;
  display: none;
}
.bckIMg .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}
.bckIMg  .left-column .inner {
  float: right;
  padding-right: 200px;
}
.bckIMg .column .inner {
  position: relative;
  display: block;
  width: 100%;
  max-width: 750px;
}
.bckIMg .image-layer::before {
  content: '';
  position: absolute;
  right: 165px;
  top: 0;
  width: 110%;
  height: 100%;
  background: #000936;
  opacity: 0.80;
  -ms-transform: skewX(-45deg);
  -webkit-transform: skewX(-45deg);
  transform: skewX(-45deg);
}
.bckIMg .image-layer.right-layer::before {
  right: auto;
  left: 165px;
  -ms-transform: skewX(45deg);
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
}
.bckIMg  .left-column .inner {
  float: right;
  padding-right: 200px;
}
.bckIMg .column .inner {
  position: relative;
  display: block;
  width: 100%;
  max-width: 750px;
}
.bckIMg .column .content {
  position: relative;
  display: block;
  padding: 90px 20px;
  text-align: center;
}
.bckIMg .column {
  position: relative;
  float: left;
  width: 50%;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.bckIMg .column h2 {
  font-size: 36px;
  position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
}
.bckIMg .column .subtitle {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 5px;
}
.bckIMg .shapes .shape.two {
  position: absolute;
  left: auto;
  right: 10px;
  top: 0;
  border: 180px solid transparent;
  border-top: 180px solid #d5ae82;
  opacity: 0.80;
}
 .more-link-box {
  position: absolute;
  left: 0;
  margin-bottom: -36px;
  bottom: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 2;
}

.rentalBlock .inneRBox:hover .titlebOx .more-link-box {
  visibility: visible;
  opacity: 1;
  margin-bottom: 0px;
}
.rentalBlock .inneRBox:hover .pricENBox {
  right: 20px;
  background: #d5ae82;
}
 .title-box .more-link-box {
  position: absolute;
  left: 0;
  margin-bottom: -36px;
  bottom: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 2;
}
.rental-block .title-box .more-link-box a {
  display: block;
  width: 100%;
  padding: 7px 15px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  background: #d5ae82;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}
 .title-box {
  position: relative;
  display: block;
  padding: 22px 20px 20px;
  text-align: center;
}
 .more-link-box {
  visibility: visible;
  opacity: 1;
  margin-bottom: 0px;
}
 .meta-info li {
  position: relative;
  float: left;
  display: block;
  width: 50%;
  padding: 12px 20px;
  text-align: right;
  font-size: 13px;
  line-height: 24px;
  color: #868da5;
  text-transform: uppercase;
  border-right: 1px solid rgba(0,0,0,0.10);
 
}

.meta-info li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #7f8a99;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}
.meta-info li .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #7f8a99;
  padding-right: 5px;
}


.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
 .meta-info ul {
  position: relative;
  width: 100%;
  padding: 0px 0px;
  border-top: 1px solid rgba(0,0,0,0.10);
  border-bottom: 1px solid rgba(0,0,0,0.10);
}
 .meta-info {
  position: relative;
}
 .titlebOx h4 a {
  color: #061138;
  text-decoration: none;
    outline: none;
} 
.titlebOx {
  position: relative;
  display: block;
  padding: 22px 20px 20px;
  text-align: center;
}
.titlebOx .info1 {
  position: relative;
  font-size: 13px;
  color: #7f8a99;
  text-transform: uppercase;
}
.titlebOx h4 {
  position: relative;
  margin-bottom: 7px;
  color: #061138;
  text-transform: capitalize;
  font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
  .servicesIcon{
    /* background-color: #E9E9F7;
      fill: #4D52D9;
      color: #4D52D9;
      border-radius: 40px 40px 40px 40px;
      display: inline-block; */
      position: relative;
      display: block;
      width: 44px;
      height: 44px;
      line-height: 44px;
      background: #232b47;
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      border-radius: 50%;
      transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease
  }
  .innovationIconA{
    font-size: 14px;
  }

  .logoSocial ul li{
    position: relative;
    display: inline-block;
    margin: 0px 8px 0px 0px;
    line-height: 44px;
  }

  ul {
    list-style: none;
    padding: 0px !important;
    margin: 0px !important;
  }
  .servicesIcon:hover{
    color: #ffffff;
      background: #d5ae82;
  }

  .footerHeader{ position: relative;
    display: inline-block;
    line-height: 1.3em;
    font-size: 24px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    font-family: 'Playfair Display', cursive;
  }
  .footerH{
    position: relative;
      margin-bottom: 30px;
      padding-bottom: 15px 
  }
  .footerH::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 70px;
    border-bottom: 1px solid rgba(255,255,255,0.20);
  }
  .bottom-text a {
    color: #d5ae82;
    text-decoration: none;
      outline: none;
  
  }
  .destinationBlock {
    position: relative;
    margin-bottom: 30px;
}

  .destinationBlock .inneRBox {
    position: relative;
    display: block;
    background: #ffffff;
}
.destinationBlock .caption-box .title {
  position: relative;
  display: block;
  padding-bottom: 5px;
}
.destinationBlock {
  position: relative;
  margin-bottom: 30px;
}
.destinationBlock .inneRBox {
  position: relative;
  display: block;
  background: #ffffff;
}

.destinationBlock .hover-box::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6,17,56,0.90);
}

.destinationBlock .inner-box:hover .hover-box {
  opacity: 1;
  visibility: visible;
}
.destinationBlock .hover-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: bottom;
  padding: 0px 0px;
}
.destinationBlock .hover-content {
  position: relative;
  width: 100%;
  display: table-cell;
  vertical-align: bottom;
  padding: 30px 40px;
}

.destinationBlock .hover-content .icon-box {
  position: relative;
  display: block;
  margin: 0 0 20px;
  line-height: 1em;
  font-size: 120px;
}
.destinationBlock .hover-content .link-box {
  position: relative;
  padding-top: 70px;
}
.destinationBlock .hover-content .link-box::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 15px;
  height: 40px;
  border-left: 1px solid rgba(255,255,255,0.30);
}
.destinationBlock .title-box h4 {
    position: relative;
    margin-bottom: 0;
    color: #061138;
    text-transform: capitalize;
    position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.bookingSection .titleCol .innerDIV {
  position: relative;
  display: block;
  padding: 130px 0px;
}
.bookingSection .titlBox .suBTitle {
  position: relative;
  font-size: 14px;
  color: #868da5;
  line-height: 1.6em;
  text-transform: uppercase;
  font-weight: 400;
  z-index: 3;
}
.STARTFaMapMarker {
  position: absolute;
  left: 25px;
  top: 20px;
  line-height: 28px;
  color: #868da5;
  display: block;
  font-size: 16px;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-slider:hover .ant-slider-track {
  background-color: #d5ae82 !important;
}
.ui-selectmenu-text {
  display: block;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select-box option {
  background-color: #f0f8ff; /* Light blue */
}

/* Styling the selected option */
/* .custom-select-box option:checked {
  background-color: #add8e6;
} */



.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 9px 20px !important;
  font-size: 15px;
  line-height: 24px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
 .booking-form .range-box {
  position: relative;
  padding-top: 12px;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-slider .ant-slider-step {
  
  background: rgba(255,255,255,0.2) !important;
  
}
:where(.css-dev-only-do-not-override-1k979oh).ant-col-12 {
  margin-top: 30px;
  flex: 0 0 100% !important; 
  max-width: 100%;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-slider .ant-slider-handle::after {
  
  box-shadow: 0 0 0 2px #d5ae82 !important;
  
}

:where(.css-dev-only-do-not-override-1k979oh).ant-slider .ant-slider-track {
  background-color: #d5ae82 !important;
}
.priceRAngaeBook{
  position: relative;
    padding: 0;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    float: right;
    background: none;
    border: none;
    color: #868da5;
    width: auto;
    min-width: 0;
    text-align: right;
}
.form-group .range-box label {
  position: relative;
  display: block;
  float: left;
  color: #7f8a99;
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
}
 .range-box .range-info {
  position: relative;
  padding-bottom: 15px;
}
.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 3px;
  right: -5px;
  text-indent: 0px;
  color: #333333;
}
.aboutSectionTwo .imageBoxDiv  .imageLayerabout {
  position: absolute;
  left: 0;
  right: 320px;
  top: -50px;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: top right;
}
.aboutSectionTwo .content-box .sec-title-two {
  margin-bottom: 35px;
}
.sec-title-two.with-border {
  padding-bottom: 40px;
}
.sec-title-two {
  position: relative;
  margin-bottom: 50px;
}
.sec-title-two .upper-text {
  position: relative;
  display: inline-block;
  line-height: 1.5em;
  font-size: 13px;
  color: #7f8a99;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 0 0 7px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
}
.sec-title-two h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.20em;
  color: #061138;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
  position: relative;
  font-weight: normal;
  margin: 0px 0px 15px;
  background: none;
  line-height: 1.25em;
  font-family: 'Playfair Display', cursive;
}
.sec-title-two h2 strong {
  font-weight: 700;
}
.aboutSectionTwo .content-box .text1 {
  
  margin-bottom: 40px !important;
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 400;
  margin: 0 0;
  font-size: 15px;
  color: #7f8a99;
  line-height: 1.6em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
}
.aboutSectionTwo .content-box .lower-row {
  position: relative;
}
.aboutSectionTwo .content-box .lower-row .info {
  position: relative;
  float: right;
  text-align: right;
  padding-right: 15px;
}
.aboutSectionTwo .content-box .lower-row .info::before {
  content: '';
  position: absolute;
  right: 0px;
  top: 6px;
  bottom: 4px;
  border-right: 2px solid #d5ae82;
}
.aboutSectionTwo .content-box .lower-row .name {
  position: relative;
  display: block;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  text-transform: capitalize;
  color: #061138;
  line-height: 1.5em;
}

.aboutSectionTwo .content-box .lower-row .info {
  position: relative;
  float: right;
  text-align: right;
  padding-right: 15px;
}
.aboutSectionTwo .content-box .lower-row .designation {
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  color: #7f8a99;
  line-height: 1.7em;
}

.aboutSectionTwo .content-box .lower-row .link-box {
  position: relative;
  float: left;
}
.btn-style-three {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: #d5ae82;
}

.btn-style-three .btn-title {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 600;
  padding: 12px 45px;
  background: #d5ae82;
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  text-transform: uppercase;
  -o-transition: all 0.3s ease;
}

.btn-style-three:hover .btn-title {
  background: #202020;
  color: #ffffff;
}

.aboutSectionTwo .content-inner .video-link {
  position: absolute;
  right: 100%;
  margin-right: 70px;
  bottom: 100px;
  display: block;
  width: 280px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.aboutSectionTwo .content-inner .video-link .video-thumb {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.aboutSectionTwo .content-inner .video-link .video-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.aboutSectionTwo .image-box-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
}
.aboutSectionTwo .image-box-right .image-layer {
  position: absolute;
  left: -200px;
  right: 0px;
  top: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.aboutSectionTwo .image-box-right .image-layer::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f0f3f7;
  opacity: 0.92;
}
.aboutSectionTwo .content-inner .video-link .overlink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #ffffff;
  background: rgba(0,0,0,0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
  outline: none;
}
.aboutSectionTwo .content-box h4 {
  margin-bottom: 25px;
  font-size: 24px;
  color: #061138;
  position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}

.aboutSectionTwo .contenOuter {
  position: relative;
  display: block;
  margin: 0px 0px 0px 400px;
  padding-left: 200px;
}
.aboutSectionTwo .content-box {
  position: relative;
  display: block;
  padding: 60px 50px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
}
.aboutSectionTwo .content-inner {
  position: relative;
  display: block;
  padding: 80px 0px 100px;
  min-height: 380px;
}
.aboutSectionTwo .imageBoxDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
}
.aboutSectionTwo {
  position: relative;
  padding: 0px 0px;
  background: #ffffff;
}
.ui-selectmenu-icon.ui-icon {
  float: right;
  margin-top: 0;
}
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}
.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 3px;
  right: -5px;
  text-indent: 0px;
  color: #333333;
}
.form-group .ui-selectmenu-button.ui-button {
  width: 100%;
  font-size: 15px;
  font-style: normal;
  height: 52px;
  padding: 10px 25px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  color: #222222;
  border-radius: 0px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
}

.ui-selectmenu-button.ui-button {
  text-align: left;
  white-space: nowrap;
  width: 14em;
}
.booking-form .form-group .ui-selectmenu-button.ui-button {
  background: none;
  border-color: rgba(255,255,255,0.20);
  color: #868da5;
  font-size: 13px;
  padding-left: 45px;
  text-transform: uppercase;
}
 .form-group select {
  position: relative;
  display: block;
  height: 52px;
  width: 100%;
  font-size: 15px;
  color: #25283a;
  line-height: 30px;
  font-weight: 400;
  padding: 10px 25px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  background: none;
  border-color: rgba(255,255,255,0.20);
  color: #868da5;
  font-size: 13px;
  padding-left: 45px;
  text-transform: uppercase;
}

.datetime-picker{
  background: none;
    border-color: rgba(255,255,255,0.20);
    color: #868da5;
    font-size: 13px;
    padding-left: 45px;
    text-transform: uppercase;
    position: relative;
    display: block;
    height: 52px;
    width: 100%;
    font-size: 15px;
    color: #25283a;
    line-height: 30px;
    font-weight: 400;
    padding: 10px 25px;
    /* background-color: #ffffff; */
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}
.bookingSection .booking-form .row {
  margin: 0 -8px;
}
.bookingSection .booking-form .row .form-group {
  position: relative;
  padding: 0 8px;
  margin-bottom: 16px;
}
.bookingSection .booking-form {
  position: relative;
  z-index: 5;
}
.bookingSection .forMCol .inner {
  position: relative;
  display: block;
  padding: 100px 0px 80px 30px;
}
.bookingSection .titlBox h2 {
  margin: 0px 0px 15px;
  background: none;
  line-height: 1.25em;
  font-family: 'Playfair Display', cursive;
  position: relative;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 3;
}
.bookingSection .titleCol .innerDIV::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 2000px;
  height: 100%;
  background: #0c1740;
}
.bookingSection .imageLayer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: left bottom;
  background-repeat: no-repeat;
  z-index: 2;
  opacity: 0.06;
}
.bookingSection {
  position: relative;
  background: #061138;
  color: #ffffff;
  padding: 0px 0px;
}
.default-link {
  position: relative;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #061138;
  font-size: 13px;
  text-decoration: none;
    outline: none;
  line-height: 24px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

 .see-all {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 10px;
}

.destinationIcon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  color: #7f8a99;
  padding-right: 5px;
}
.destinationBlock .meta-info li a:hover {
  color: #d5ae82;
  text-decoration: underline;
}
.destinationBlock .title-box h4 a:hover {
  color: #d5ae82;
}
.destinationBlock .title-box h4 a {
  text-decoration: none;
  outline: none;
  color: #061138;
}

.destinationBlock .hover-content .link-box .theme-btn {
  display: block;
  text-decoration: none;
    outline: none;
}
.destinationBlock .hover-content .link-box .theme-btn .btn-title {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-style-two .btn-title {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 45px;
  background: none;
  border: 2px solid #ffffff;
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  
}

.destinationBlock .inneRBox:hover .caption-box {
  opacity: 0;
  visibility: hidden;
}

.destinationBlock .inneRBox:hover .hover-box {
  opacity: 1;
  visibility: visible;
}
.btn-style-two:hover .btn-title {
  background: #d5ae82;
  border-color: #d5ae82;
  color: #ffffff;
}

.btn-style-two {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  text-decoration: none;
    outline: none;
}
.destinationBlock .hover-content h5 {
  position: relative;
  display: block;
  margin: 0 0;
  font-size: 20px;
  background: none;
  font-weight: normal;
  line-height: 1.25em;
  font-family: 'Playfair Display', cursive;
  text-transform: uppercase;
}
.destinationBlock .hover-box {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.destinationBlock .inneRBox:hover .caption-box {
  opacity: 0;
  visibility: hidden;
}
.destinationBlock .caption-box .count {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  font-size: 72px;
  line-height: 1em;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
  opacity: 0.12;
}
.destinationBlock .caption-box {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  padding: 0px 20px;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.destinationBlock .imaGEBox img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.destinationBlock .imaGEBox {
  position: relative;
  display: block;
  top: 0;
  overflow: hidden;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
  .bottom-text {
    position: relative;
    padding-top: 80px;
    text-align: center;
    font-size: 20px;
    line-height: 1.5em;
    font-style: italic;
    text-transform: capitalize;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
  }
  .links li a::before{
    content: "+";
      font-family: 'Playfair Display', serif;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 22px;
      font-size: 20px;
      font-weight: 400;
  }


.links li a{
  position: relative;
    display: block;
    color: #868da5;
    font-size: 15px;
    padding-left: 20px;
    text-transform: capitalize;
    transition: all 0.4s ease;
    text-decoration: none;
}

.links li{
  position: relative;
    margin-bottom: 12px;
    line-height: 24px;
    font-size: 15px;
    color: #868da5;
}

.links li a:hover { 
  color: #d5ae82;
  /* text-decoration: underline; */
}


.footerImage{
  position: relative;
  float: left;
  width: 25%;
  display: block;
  margin: 0 0 10px;
  padding: 0px 5px;
}

.footerImage img{
  width: 100%;
}

.copyrightData{
  position: relative;
    float: left;
    padding: 10px 0px;
    font-size: 15px;
}

.copyTerms{
    position: relative;
    float: right;
    padding: 10px 0px;
    font-size: 15px;
}
.copyrightd{
  position: relative;
    line-height: 24px;
    padding: 12px 0px;
    text-transform: capitalize;
    border-top: 1px solid rgba(255,255,255,0.10);
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* footer---css */




/* navbae css */

.btnBookNow{
  position: relative;
    display: block;
    font-size: 13px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    padding: 10px 38px;
    min-height: 42px;
    background: #061138;
    border-radius: 0px;
    z-index: 1;
}
.btnBookNow:hover{
  background-color: #d5ae82;
}
.product_text ul li Link::before{
  content: '';
    position: absolute;
    left: 0;
    top: 22px;
    height: 4px;
    width: 4px;
    background: #868da5;
}

.logoDesktop{
  position: absolute;
    left: 0;
    top: 0;
    width: 230px;
    height: 140px;
    z-index: 5;

}

.topHeader{
  background: #061138;
  color: #ffffff;
  padding: 0px;
  line-height: 30px;
  overflow-x: hidden;
  position: relative;
}


/* .inner{
  position: relative;
  padding: 0px 250px;
  height: 52px;
  display: block;
} */
.top-left {
  float: left;
}
.more-link-box {
  position: absolute;
  left: 0;
  margin-bottom: -36px;
  bottom: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 2;
}
 .title-box .more-link-box {
  position: absolute;
  left: 0;
  margin-bottom: -36px;
  bottom: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 2;
}
figure {
  display: block;
  margin: 0 !important;
  padding: 0;
}
.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
 .more-link-box a {
  display: block;
  text-decoration: none;
    outline: none;
  width: 100%;
  padding: 7px 15px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  background: #d5ae82;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}
.rentalBlock .inneRBox:hover .title-box .more-link-box {
  visibility: visible;
  opacity: 1;
  margin-bottom: 0px;
}
/* .info {
  position: relative;
  float: left;
  padding: 10px 0px;
  padding-right: 32px;
  
} */
 .info1 li {
  position: relative;
  float: left;
  line-height: 30px;
  margin-right: 61px;
  font-size: 15px;
}
 .info1 li a {
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
}
.info1 li a span::before {
  content: '';
  position: absolute;
  right: -30px;
  top: 5px;
  height: 20px;
  border-right: 1px solid rgba(255,255,255,0.20);
}

.hederIcon{
  font-size: 15px;
    color: #d5ae82;
    padding-right: 5px;
    font-weight: 900;
}
.newsPara{
  position: relative;
    display: inline-block;
    line-height: 1.5em;
    font-size: 13px;
    color: #7f8a99;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin: 0 0 7px;
    font-family: 'Poppins', sans-serif;
}
.newHeder{
  position: relative;
    display: block;
    font-size: 40px;
    line-height: 1.20em;
    color: #061138;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-family: 'Playfair Display', cursive;
}

.separator{
  position: absolute;
left: 0;
bottom: 0;
display: block;
width: 70px;
height: 7px;
background-position: left center;
background-repeat: no-repeat;

}


.btnNew{
  position: relative;
    display: block;
    font-size: 13px;
    line-height: 28px;
    color: #061138;
    font-weight: 600;
    padding: 10px 45px;
    background: none;
    border: 2px solid #061138;
    border-radius: 0px;
    text-transform: uppercase;
    z-index: 1;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    font-family: 'Poppins', sans-serif;
}
.btnDiv{
  position: relative;
    float: right;
    padding-top: 50px;
    margin-bottom: 30px;
}
.btnNew:hover{
  background: #061138;
    border-color: #061138;
    color: #ffffff;
}

.imgDiv{
  position: absolute;
    left: 0;
    top: 0;
    width: 170px;
}

/* .newFluid{
  position: relative;
  padding: 90px 0px 40px;
  background: #ffffff;
} */

/* .post-date{
  position: absolute;
    left: 0;
    top: 210%;
    width: 60px;
    height: 60px;
    padding: 6px 5px;
    text-align: center;
    line-height: 24px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
} */

.day{
  display: block;
    font-size: 20px;
    color: #061138;
}


.sailingPara{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #b5c0cf;
  padding-right: 5px;
}

.flogerColor{
  position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;
    color: #b5c0cf;
    /* padding-right: 5px; */
    margin-right: 10px;
}

.sailingHeder{
  margin-right: 40px;
}
.sailingHeders{
  margin-right: 40px;
}

.sailingHeder::before{
  content: '';
  position: absolute;
  right: 140px;
  top: 5px;
  width: 6px;
  height: 6px;
  background: #d1d8e2;
  border-radius: 50%;
}

.saiHover:hover{
  color: #d5ae82;
    text-decoration: underline;
}
.saiLinks{
  text-decoration: none;
  color: #7f8a99;
}
.sailingHeders::before{
  content: '';
  position: absolute;
  right: 162px;
  top: 5px;
  width: 6px;
  height: 6px;
  background: #d1d8e2;
  border-radius: 50%;
}
.palnheder{
  color: #061138 !important;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-size: 24px;
  position: relative;
  font-weight: normal;
  margin: 0px 0px 15px;
  background: none;
  line-height: 1.25em;
  font-family: 'Playfair Display', cursive;
}
.palnheder a{
  text-decoration: none;
  color: #061138;
}

.palnheder a:hover{
  color: #d5ae82;
    text-decoration: underline;
}

.plnPara{
  margin: 0 0;
  font-size: 15px;
  color: #7f8a99;
  line-height: 1.6em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}


.continueSapn{
  position: relative;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: #061138;
    font-size: 13px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.continueHover:hover .continueSapn{
  color: #d5ae82;
}
.formStyle{
  position: relative;
    padding: 48px 30px 50px;
    background: #081853;
    background: -webkit-linear-gradient(left,#081853,#061138);
}

.subscribeUs{
  position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    color: #ffffff;
    font-size: 24px;
    margin: 0 0 7px;
    text-transform: uppercase;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.subscribePara{
  color: #ffffff;
    font-size: 13px;
    text-transform: uppercase;
}

#contact_partner input{
  position: relative;
    display: block;
    height: 52px;
    width: 100%;
    font-size: 15px;
    color: #25283a;
    line-height: 30px;
    font-weight: 400;
    padding: 10px 25px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    -webkit-transition: all 300ms ease;
    font-family: 'Playfair Display', serif;
    font-style: italic;
    padding-left: 20px !important;

    margin-bottom: 20px;
}

.subscribebtn{
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 600;
  padding: 12px 45px;
  background: #d5ae82;
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  text-align: center;
  text-transform: uppercase;
}
.subscribebtn:hover{
  background-color: #212529;
}


.testimonalDiv{
  position: relative;
  padding: 90px 0px;
  z-index: 1;
}

.imgTestimonalImg{
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: -110px;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.imgTestimonal{
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.imgTestimonalImg::before{
  content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #061138;
      opacity: 0.90;
  }
/* .imgTestimonal::before{
content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #061138;
    opacity: 0.90;
} */

.innerBox{
  position: relative;
  display: block;
  background: #ffffff;
  padding: 40px 40px;
  margin-right: 17px;
}

.innerDiv{
  position: relative;
    display: block;
    padding-left: 110px;
    min-height: 150px;
}
.imgDivsss{
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
}
.FreedomDiv{
  position: relative;
    display: block;
    color: #ffffff;
    padding: 50px 0px;
    overflow: hidden;
}
.FreedomBox{
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* -webkit-transform: scale(1); */
    /* -ms-transform: scale(1); */
    /* transform: scale(1); */
    /* -webkit-transform: scale(1.15); */
     /* -ms-transform: scale(1.15); */
      /* transform: scale(1.15);  */
      -webkit-transition: all 7000ms linear; 
      -moz-transition: all 7000ms linear; 
      -ms-transition: all 7000ms linear;
       -o-transition: all 7000ms linear;
        transition: all 7000ms linear; 
}
.active .contentSkiing .textSkiing{
  opacity: 1;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 700ms;
  -moz-transition-delay: 700ms;
  -ms-transition-delay: 700ms;
  -o-transition-delay: 700ms;
  transition-delay: 700ms;
}
.innerBox {
  position: relative;
  display: block;
  padding: 40px 20px 50px;
  background: #ffffff;
  text-align: center;
}

.innerBox:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: block;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.08);
  background: #ffffff;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.iconBoxss{
  position: relative;
    top: 0;
    display: block;
    line-height: 1em;
    font-size: 64px;
    color: #d5ae82;
    margin-bottom: 20px;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

.contentboX {
  position: relative;
  display: block;
  padding: 40px 0px;
  height: 180px;
  margin-bottom: 20px;
}

.contentboX:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0px;
  bottom: 0px;
  border-left: 1px solid rgba(0,0,0,0.10);
}
 .titleboX {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 100px;
  background: #ffffff;
  padding: 10px 10px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.titleboX h4 {
  color: #061138;
  line-height: 36px;
  margin: 0 0 5px;
  position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}

.titleboXbox .subtitle {
  position: relative;
  color: #7f8a99;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
}
.featuredService .innerBox:hover .text {
  opacity: 1;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
 .text {
  position: absolute;
  left: 0;
  top: 35px;
  width: 100%;
  height: 100px;
  color: #868da5;
  font-size: 15px;
  background: #ffffff;
  padding: 10px 20px;
  line-height: 26px;
  opacity: 0;
  -ms-transform: scale(0.90);
  -webkit-transform: scale(0.90);
  transform: scale(0.90);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
/* .scroll-to-top .icon {
  position: relative;
  display: block;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.scroll-to-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 62px;
  height: 62px;
  color: #757d97;
  font-size: 24px;
  line-height: 60px;
  border: 1px solid #384160;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #061138;
  border-radius: 0px;
  display: none;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.scroll-to-top .icon::before{
  font-family: 'Font Awesome 5 Free';
    content: "^";
    position: absolute;
    right: 0px;
    top: 12px;
    display: block;
    line-height: 24px;
    font-size: 16px;
    font-weight: 900;
    z-index: 5;
} */
 .active .contentSkiing .linksboxSkiing {
  opacity: 1;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 1100ms;
  -moz-transition-delay: 1100ms;
  -ms-transition-delay: 1100ms;
  -o-transition-delay: 1100ms;
  transition-delay: 1100ms;
}
.active .contentSkiing h1{
  opacity: 1;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 300ms;
  -moz-transition-delay: 300ms;
  -ms-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  transition-delay: 300ms;
}

.active .FreedomDiv .FreedomBox {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition: all 7000ms linear;
  -moz-transition: all 7000ms linear;
  -ms-transition: all 7000ms linear;
  -o-transition: all 7000ms linear;
  transition: all 7000ms linear;
}
.contentBoxSkiing h1{
  
  display: block;
    font-size: 90px;
    line-height: 1.10em;
    font-family: 'Playfair Display', cursive;
    color: #ffffff;
    font-weight: 800;
  
    margin-bottom: 50px;
    text-transform: capitalize;
    letter-spacing: 0;
    -webkit-transform: translateY(80px);
    -moz-transform: translateY(80px);
    -ms-transform: translateY(80px);
    -o-transform: translateY(80px);
    transform: translateY(80px);
    text-transform: capitalize;
}
.innerFloat{
  float: right;
}
.btnTitleExplore{
position: relative;
display: block;
font-size: 13px;
line-height: 28px;
color: #ffffff;
font-weight: 600;
padding: 10px 45px;
background: none;
border: 2px solid #ffffff;
border-radius: 0px;
z-index: 1;
transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-webkit-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
}
.btnTitleExplore:hover{
  background: #d5ae82;
    border-color: #d5ae82;
    color: #ffffff;
}
.themeBtnSkiing {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    color: #d5ae82;
}
.linksboxSkiing{
  position: relative;
    
    z-index: 5;
    -webkit-transform: translateY(80px);
    -moz-transform: translateY(80px);
    -ms-transform: translateY(80px);
    -o-transform: translateY(80px);
    transform: translateY(80px);
}
.textSkiing{
  display: block;
    font-size: 16px;
    line-height: 1.7em;
    color: #ffffff;
    font-weight: 400;
    opacity: 1;
    margin-bottom: 42px;
    max-width: 520px;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
.innerSkiing{
  position: relative;
    display: block;
    width: 100%;
    max-width: 600px;
}
.contentSkiing{
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 50px 0px;
}
.contentBoxSkiing{
  position: relative;
  display: table;
  vertical-align: middle;
  width: 100%;
  height: 740px;
  padding: 20px 0px;
  z-index: 5;
}
.autoContainerDiv{
  position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}
.formDiv{
  position: relative;
  display: block;
  padding-left: 20px;
  margin-top: -100px;
  z-index: 2;
}
.titleBook{
  text-align: center;
  color: #061138;
  margin-bottom: 20px;

}
.form-group {
  position: relative;
  padding: 0 10px;
  margin-bottom: 15px;
}
.fieldInner input{
  position: relative;
  display: block;
  height: 52px;
  width: 100%;
  font-size: 15px;
  color: #25283a;
  line-height: 30px;
  font-weight: 400;
  padding: 10px 25px;
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.fieldInner select{
  position: relative;
  display: block;
  height: 52px;
  width: 100%;
  font-size: 15px;
  color: #25283a;
  line-height: 30px;
  font-weight: 400;
  padding: 10px 25px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: 'Poppins', sans-serif;
}
.themebtn{
  width: 100%;
    text-align: center;
    display: block;
    margin-top: 10px;
}
.themebtn :hover{
  background: #202020;
    color: #ffffff;
}
.minus{
  position: absolute;
  left: 0px;
  top: 1px;
  width: 53px;
  height: 50px;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 0;
  text-align: center;
  background: none;
  font-weight: 400;
  color: #7f8a99;
  border-right: 1px solid #e5e5e5;
  z-index: 1;
  cursor: pointer;
}
.plus{
  position: absolute;
  right: 0px;
  top: 1px;
  width: 53px;
  height: 50px;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 0;
  text-align: center;
  background: none;
  font-weight: 400;
  color: #7f8a99;
  border-left: 1px solid #e5e5e5;
  z-index: 1;
  cursor: pointer;
}
.prod_qty{
  background: none;
    margin: 0px;
    font-weight: 400;
    text-align: center;
}
.btnTitleSpan{
  position: relative;
    display: block;
    font-size: 13px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 600;
    padding: 12px 45px;
    background: #d5ae82;
    border-radius: 0px;
    z-index: 1;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    text-transform: uppercase;
}
.quantitySpinner {
  position: relative;
  display: block;
}
.fieldlLabel{
  position: relative;
    display: block;
    color: #7f8a99;
    font-size: 13px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.titleBook h4{
  color: #061138;
    margin: 0;
    position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.formbox {
  position: relative;
  padding: 45px 32px 40px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.07);
  -ms-box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.07);
  box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.07);
}
.testimonalyData{
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 22px;

}
.testimonalyName{
  position: relative;
    color: #061138;
    margin-bottom: 3px;
    font-weight: normal;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.testimonlyDes{
  position: relative;
  color: #75808f;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
}

.testimonalyData::before{
  content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 70px;
    border-bottom: 1px solid rgba(0,0,0,0.10);
}

.ratediv{
position: absolute;
    right: -56px;
    top: 0px;
    line-height: 24px;
    padding: 3px 15px;
    background: #d5ae82;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 1px;
}
.ratediv::before{
  content: '';
    position: absolute;
    right: 0;
    top: -16px;
    border: 8px solid rgba(0,0,0,0);
    border-left: 8px solid #b09373;
    border-bottom: 8px solid #b09373;
}
.textData{
  position: relative;
    color: #75808f;
    font-size: 17px;
    line-height: 1.7em;
    font-weight: 400;
    font-family: 'Playfair Display', serif;
}
.testLogo{
  position: absolute;
    left: 0;
    top: 100px;
    width: 70px;
    text-align: center;
    font-size: 48px;
    color: #cdd5df;
    line-height: 1em;
}
.palnLogo{
  position: relative;
  display: block;
  margin-left: -285px;
  text-align: right;
}
.priceDiv{
  position: relative;
    display: block;
    padding: 45px 30px 25px;
    background: #ffffff;
    border-bottom: 7px solid #d5ae82;
    box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.10);
}
.planHeader{
  position: relative;
  display: block;
  text-align: center;
  
}
.planTitle{
  font-size: 24px;
    color: #061138;
    text-transform: uppercase;
    margin-bottom: 7px;
    position: relative;
    font-weight: normal;
    
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}

/* .subtitle{
  font-size: 13px;
    color: #7b8594;
    text-transform: uppercase;
    font-weight: 400;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
} */
.price{
  position: relative;
    display: block;
    margin: 15px 0px;
}


.amount{
  position: relative;
  font-size: 60px;
  color: #d5ae82;
  font-weight: 700;
  line-height: 1em;
  vertical-align: top;
  font-family: 'Poppins', sans-serif;
}
.sign{
  position: relative;
    font-size: 24px;
    color: #d5ae82;
    font-weight: 600;
    line-height: 1.5em;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
}
.bestTitle{
  position: relative;
    font-size: 13px;
    color: #7b8594;
    text-transform: uppercase;
}
.bestTitle::before{
  content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.10);
    margin-top: -0.5px;
}
.bestTitle span{
  position: relative;
    display: inline-block;
    padding: 0px 15px;
    background: #ffffff;
}
.planFeatures{
  position: relative;
  padding-top: 15px;
  color: #808080;
}
.planFeatures li{
  position: relative;
    margin-bottom: 7px;
    line-height: 24px;
    /* padding-left: 22px; */
    font-family: 'Poppins', sans-serif;
    color: #808080;
}

.ckeckMark{
  left: 0px;
  top: 0px;
  font-size: 13px;
  font-weight: 800;
  color: #d5ae82;
}
.linkBox{
  position: absolute;
    left: 0;
    bottom: -36px;
    width: 100%;
    display: block;
    opacity: 0;
    visibility: hidden;
    -ms-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -ms-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.linkBox a{
  position: relative;
    display: block;
    color: #ffffff;
    font-size: 13px;
    line-height: 24px;
    padding: 6px 20px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    background: #d5ae82;
    text-decoration: none;
    outline: none;
}


.priceDiv:hover .linkBox {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}


.qualitySection{
  position: relative;
    padding: 90px 0px 20px;
    background: #061138;
    color: #ffffff;
    overflow: hidden;
}
.innerBoxBest{
  position: relative;
  display: block;
  padding-left: 110px;
  min-height: 80px;
}
.iconBox{
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -40px;
  width: 80px;
  height: 80px;
}
.iconBox::before{
  content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-bottom: 10px;
    border-left: 1px solid rgba(255,255,255,0.30);
    height: 300px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.iconBox::after{
content: '';
position: absolute;
left: 50%;
top: 100%;
margin-top: 10px;
border-left: 1px solid rgba(255,255,255,0.30);
height: 300px;
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}
.octagonWrap{
  position: relative;
  width: 80px;
  height: 80px;
  float: left;
  overflow: hidden;
}
.octagon{
position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #061138;
    border: 1px solid rgba(255,255,255,0.30);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
 .octagon:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  border: inherit;
}
.qualityDiv{
  position: relative;
    margin-bottom: 70px;
}
.qtyLog{
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 42px;
  color: #d5ae82;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.logoQty{
  font-family: 'Flaticon' !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    line-height: 80px;
    text-align: center;
    font-size: 42px;
    color: #d5ae82;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
}
.QualityHeder{
  position: relative;
    font-weight: normal;
    margin: 0px 0px 15px;
    font-size: 24px;
    line-height: 1.3em;
    margin-bottom: 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.Qualitytext{
  color: #868da5;
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 1.6em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}
.continueSapna:hover{
  color: #d5ae82;
}
.continueSapna{
  position: relative;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
}

.qualityDiv:hover .iconBox .octagon {
  background: #d5ae82;
  border-color: #d5ae82;
  color: #fff;
}
.qualityDiv:hover .iconBox .logoQty {
  
  color: #fff;
}
.carousel-controls{
  position: relative;
    width: 100%;
    /* padding-top: 40px; */
    text-align: center;
 /* margin: 0px 7px;  */
}
.owlBtn{
  position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 0;
    margin-top: -35px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.leftBtn{
  position: absolute;
  left: 20px;
  background: none;
  height: 64px;
  width: 64px;
  border: none;
  text-align: center;
  line-height: 62px;
  border: 1px solid rgba(0,0,0,0.10);
  color: #acb7c6;
  font-size: 20px;
  font-weight: 700;
  border-radius: 0;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.rightBtn{
  position: absolute;
  right: 20px;
  background: none;
  height: 64px;
  width: 64px;
  border: none;
  text-align: center;
  line-height: 62px;
  border: 1px solid rgba(0,0,0,0.10);
  color: #acb7c6;
  font-size: 20px;
  font-weight: 700;
  border-radius: 0;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.activitiesSection {
  position: relative;
  padding: 90px 0px;
  background: #f0f3f7;
}
.activitiesSectioncls {
  position: relative;
  padding: 90px 0px;
  /* background: #f0f3f7; */
}
.innerBoxPop{
  position: relative;
    display: block;
    max-width: 980px;
    margin: 0 auto;
    background: #ffffff;
    padding-left: 270px;
    min-height: 300px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.12);
}
.imgDiv{
  position: absolute;
  left: 0;
  top: 0;
  width: 270px;
  height: 300px;
  overflow: hidden;
}
.contentBox {
  position: relative;
  padding: 30px 30px 20px;
}
.Scuba{
  position: relative;
    font-size: 26px;
    color: #061138;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.Scuba a{
  color: #061138;
    text-decoration: none;
}
.addtofav{
  position: absolute;
    right: 25px;
    top: 40px;
    z-index: 1;
}
.ratings{
  position: relative;
    margin-bottom: 15px;
}
.rateText{
  position: relative;
  color: #061138;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}
.rateText strong{
  position: relative;
    font-size: 24px;
    font-weight: 500;
}
.rateText a{
  color: #061138;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
    outline: none;
}
.rateImg{
  color: #d5ae82;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
}
.infoDuration {
  position: relative;
}
.infoDuration li{
  position: relative;
    float: left;
    font-size: 14px;
    line-height: 24px;
    margin-right: 31px;
    color: #7f8a99;
    line-height: 1.6em;
    font-weight: 400;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
}
.infoDuration li .lineSpance::after{
  content: '';
  position: absolute;
  right: -16px;
  top: 5px;
  height: 14px;
  border-right: 1px solid rgba(0,0,0,0.15);
}
.linksBox{
  position: relative;
    margin-top: 90px;
}
.linkDIv{
  position: relative;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.infoBtnBox{
  position: relative;
  z-index: 3;
}
.infoBtn{
  position: relative;
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #7f8a99;
  font-weight: 600;
  padding: 11px 15px;
  text-transform: uppercase;
  background: none;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  cursor: pointer;
}

.btnStyleFour{
  position: relative;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    text-decoration: none;
    outline: none;
}
.btnTitle{
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #061138;
  font-weight: 600;
  padding: 11px 45px;
  background: none;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btnStyleFour:hover .btnTitle {
  background: #d5ae82;
  border-color: #d5ae82;
  color: #ffffff;
}

.infoBtn:hover{
  background: #d5ae82;
  border-color: #d5ae82;
  color: #ffffff;
}
.pricing{
  position: relative;
  float: right;
  text-align: right;
  font-size: 12px;
  color:#7f8a99;
  font-family: 'Poppins', sans-serif;
}
.priceTitles{
  display: block;
  text-transform: uppercase;
}
.unit{
  display: block;
    color: #061138;
    font-family: 'Playfair Display', serif;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 500;
}
.unit span{
  font-size: 20px;
}

.seeAll{
  position: relative;
  display: block;
  text-align: center;
  padding-top: 50px;
}
.btnStyleFive{
  position: relative;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    text-decoration: none;
    outline: none;
}
.btntitleALL{
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 28px;
  color: #061138;
  font-weight: 600;
  padding: 10px 45px;
  background: none;
  border: 2px solid #061138;
  border-radius: 0px;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btnStyleFive:hover .btntitleALL {
  background: #061138;
  border-color: #061138;
  color: #ffffff;
}
.upperRow{
  position: relative;
    padding-left: 300px;
    min-height: 232px;
}
.imageboxAbout{
  position: absolute;
  left: 0;
  top: 0;
  width: 270px;
  height: 232px;
  overflow: hidden;
}
.imageboxAbout img {
  display: block;
  width: 100%;
}
.secTitle{
  top: -5px;
    margin-bottom: 27px;
    position: relative;
}
.upperText{
  position: relative;
    display: inline-block;
    line-height: 1.5em;
    font-size: 13px;
    color: #7f8a99;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin: 0 0 7px;
}
.WhoPara{
  position: relative;
    display: block;
    font-size: 30px;
    line-height: 1.25em;
    color: #061138;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0px;
    font-family: 'Playfair Display', cursive;
}

.videoLink{
  position: relative;
    display: block;
    padding-left: 80px;
    min-height: 60px;
}
.videoThumb{
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.overlink{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #ffffff;
  background: rgba(0,0,0,0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.videoThumb img{
  display: block;
  width: 60px;
  height: 60px;
}
.videoTitle{
  position: relative;
}
.videoTitle a{
  position: relative;
    display: inline-block;
    padding: 10px 0px;
    color: #061138;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.5em;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
.lowerContent{
  position: relative;
  padding-top: 40px;
}
.textAbouTPara{
  position: relative;
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 1.6em;
    font-weight: 400;
    font-size: 15px;
    color: #7f8a99;
    line-height: 1.6em;
    font-weight: 400;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
}


.innerBoxWhat{
  position: relative;
    display: block;
    padding-bottom: 72px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.imageBoxWhat{
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 5px;
}
.imageBoxWhat::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 120%;
  left: -10%;
  height: 50px;
  background: #ffffff;
  border-top: 7px solid transparent;
  -ms-transform: rotate(-17deg);
  -webkit-transform: rotate(-17deg);
  transform: rotate(-17deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}
.imageBoxWhat img{
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.lowerBoxWhat{
  position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin-left: 40px;
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 2px;
    border-radius: 0px 0px 3px 3px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.12);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 3;
}

.lowerContentWhat{
  position: relative;
  display: block;
  background: #ffffff;
  padding: 35px 40px;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px 0px 3px 3px;
  z-index: 1;
}
.iconBoxAbout{
  position: absolute;
    right: 30px;
    top: -45px;
    width: 90px;
    height: 90px;
    background: #061138;
    color: #d5ae82;
    text-align: center;
    font-size: 42px;
    line-height: 90px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.catWhat{
  position: relative;
    display: block;
    color: #7b8594;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 7px;
    font-family: 'Poppins', sans-serif;
}
.lowerContentWhat h4{
  position: relative;
    color: #061138;
    margin-bottom: 20px;
    font-weight: normal;
    margin: 0px 0px 15px;
    background: none;
    line-height: 1.25em;
    font-family: 'Playfair Display', cursive;
}
.lowerContentWhat h4 a{
  color: #061138;
  text-decoration: none;
}
.continueSapnaWhat{
  position: relative;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: #061138;
    font-size: 13px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
}

.service-block .innerBoxWhat:hover .lowerBoxWhat {
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
}

.service-block .innerBoxWhat:hover .lowerContentWhat .iconBoxAbout {
  background: #d5ae82;
  color: #ffffff;
}
.service-block .innerBoxWhat:hover .continueHover .continueSapnaWhat {
  color: #d5ae82;
}
/* cdcsdcdsccccccccd */











  .d-none {
    display: none;
  }

  .border-bottom-1 {
    border-bottom: 1px solid #696969;
  }
  .border-bottom-white {
    border-bottom: 1px solid #fff;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* color: #454545; */
    color: #1d1d1f;
    font-weight: 600;
  }

  h2 {
    font-size: 32px;
    line-height: 39px;
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  .text_div_404 {
    min-height: calc(100vh - 104px);
  }

  .ul_404 {
    text-align: center;
    padding-top: 66px;
    justify-content: center;
    display: flex;
    margin: auto;
    color: white;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;

    list-style: none;
  }
/* 
  @keyframes elementor-animation-buzz {
    50% {
        transform: translateX(3px) rotate(2deg)
    }

    100% {
        transform: translateX(-3px) rotate(-2deg)
    }
  }

  .elementor-animation-buzz:active,.elementor-animation-buzz:focus,.elementor-animation-buzz:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: .15s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
  }

  @keyframes elementor-animation-buzz {
    16.65% {
      transform: translateY(8px)
  }

  33.3% {
      transform: translateY(-6px)
  }

  49.95% {
      transform: translateY(4px)
  }

  66.6% {
      transform: translateY(-2px)
  }

  83.25% {
      transform: translateY(1px)
  }

  100% {
      transform: translateY(0)
  }
  }

  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }

  @keyframes fadeInUp {
    60%,75%,90%,from,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  from {
      opacity: 0;
      transform: translate3d(0,3000px,0)
  }

  60% {
      opacity: 1;
      transform: translate3d(0,-20px,0)
  }

  75% {
      transform: translate3d(0,10px,0)
  }

  90% {
      transform: translate3d(0,-5px,0)
  }

  to {
      transform: translate3d(0,0,0)
  }
  }

  .elementor-animation-buzz:active,.elementor-animation-buzz:focus,.elementor-animation-buzz:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  p:active,p:focus,p:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
  h4:active,h4:focus,h4:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  h1:active,h1:focus,h1:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  h3:active,h3:focus,h3:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  } */

  /* li:active,li:focus,li:hover {
    animation-name: elementor-animation-buzz;
    animation-duration: 1s !important;
    animation-timing-function: ease-in-out !important;
    animation-iteration-count: 1 !important;
  } */

  .tel_no{
    text-decoration: none;
    color: #038ee2 !important;
  }
  .ul_404 li {
    margin-right: 40px;
    padding-bottom: 5px;
  }
  .ul_404 li:hover {
    border-bottom: 1px solid #fff;
  }
  button {
    border: 0px;
  }
  .pt-60px {
    padding-top: 60px;
  }
  .line-height-30 {
    line-height: 30px;
  }
  .font-size-20 {
    font-size: 20px !important;
  }
  .color-brown {
    color: #e5794c !important;
  }

  .color-yellow {
    color: #ecc054 !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    /* color: inherit; */
  }
  /* .section_bottom_margin img {
    background: rgba(35, 34, 37, 0.30);
  } */
  .directorshedaer{
    padding: 0px; 
    /* margin: 0px; */
    font: 400 34px/40px 'Poppins';
    padding-bottom: 20px;
    /* padding: 31px; */
    /* margin: 12px 36px; */
    margin-top: 50px;
    /* margin-left: 31px; */
  }
  .directorshedaerp{
    /* margin-left: 31px; */
      font: 400 18px/30px 'Poppins';
      padding-bottom: 20px;
      color: #506175;
  }
  .cardborderstyle{
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.1);
      padding: 60px 50px 30px;
      background: #fff;
      border-radius: 5px;
      margin-top: 20px;
  }
  .stylRecruitment{
    font: 700 24px/33px 'Poppins';
      padding-bottom: 20px;
      color: #038EE2;
  }

  .list_styless{
    width: 100%;
    display: inline-block;
    padding-left: 0;
    list-style: none;
  }
  .flex-grow-1 h4{
    color: #038EE2;
    font-size: 16px;
      font-weight: 700;
      padding: 0px;
      line-height: 20px;
  }
  .blog_box1::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #2A333B;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blog_box img {
    height: 420px;
    width: 100%;
    object-fit: cover;
  }
  .blog_box span{
    display: inline-flex;
      align-items: center;
      min-height: 30px;
      background: rgba(0,0,0,0.6);
      max-width: 82px;
      width: 100%;
      padding: 2px;
      justify-content: center;
      position: absolute;
      top: 22px;
      left: 29px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: #fff;
      z-index: 4;
  }
  .blog_text{
    position: absolute;
      bottom: 0;
      left: 0;
      padding: 35px 24px;
      padding-right: 17px;
      z-index: 3;
  }

  .blog_text h5{
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    padding-bottom: 9px;
    font-weight: 700;
      font-family: 'Poppins';
  }
  .blog_text small{
    font-size: 14px;
      line-height: 20px;
      color: #00ADE9;
      font-weight: 700;
      display: inline-block;
  }
  .blog_box{
    position: relative;
      border-radius: 5px;
      overflow: hidden;
  }
  .cardstylt:hover .flex-grow-1 span {
    color: #fff;
  }
  .cardstylt:hover .flex-grow-1 h4 {
    color: #fff;
  }
  .flex-grow-1 span{
    font-weight: 500;
      font-size: 16px;
    color: #506175;
  }
  .recruitmentImg{
    width: 80px;
      height: 80px;
      border-radius: 100%;
      object-fit: cover;
  }
  .fleximg{
    flex-shrink: 0 !important;
  }
  .cardstylt:hover .list_styless li {
    color: #fff;
  }
  .cardstylt:hover .stylRecruitment{
    color: #fff;
  }
  .cardstylt:hover {
    background: linear-gradient(298.06deg, #038EE2 2.91%, #2B3990 98.77%) !important;
  }
  .list_styless li{
    display: inline-block;
      width: 100%;
      margin: 0 0 5px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      color: #038EE2;
      font-size: 13px;
      line-height: 20px;
      
      float: left;
  }
  .list_styless li:hover {
    color: #fff;
  }
  .cardstylt{
    width: 100%;
      background: #EBFAFF;
      padding: 30px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      text-align: left;
      border-radius: 10px;
      min-height: 383px;
  }
  body {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f5f5f7;
    font-family: "Inter";
    font-style: normal;
    line-height: 19px;
    color: #6f6f6f;
  }

  .p-200 {
    padding: 200px;
  }

  .p-25 {
    padding: 25px;
  }

  .px-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .btn-primary {
    background: #1764d8;
    border: 1px solid #1764d8;
    border-radius: 6.32504px;
    height: 54.82px;
    color: #fff;
    padding: 15px 20px;
  }

  .m-auto {
    margin: auto;
  }
  .m-2 {
    margin: 1rem;
  }
  .py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .fw-500 {
    font-weight: 500;
  }
  .p-1 {
    padding: 0.5rem;
  }
  .p-2 {
    padding: 1rem;
  }
  .ml-1 {
    margin-left: 0.5rem;
  }
  .ml-2 {
    margin-left: 1rem;
  }
  .ml-3 {
    margin-left: 1.5rem;
  }
  .ml-4 {
    margin-left: 3rem;
  }
  .ml-0 {
    margin-left: 0px;
  }

  .ml-auto {
    margin-left: auto;
  }

  .text-center {
    text-align: center;
  }

  .mt-5 {
    margin-top: 3rem;
  }
  .mt-4 {
    margin-top: 1.5rem;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  .mb-5 {
    margin-bottom: 3rem;
  }

  .mb-2 {
    margin-bottom: 1rem;
  }
  .mb-3 {
    margin-bottom: 1.5rem;
  }

  .mb-100px {
    margin-bottom: 100px;
  }

  .mb-50px {
    margin-bottom: 50px;
  }
  .mr-2 {
    margin-right: 1rem;
  }
  .mr-30 {
    margin-right: 2%;
  }
  .ml-30 {
    margin-left: 2%;
  }
  .pt-0 {
    padding-top: 0px !important;
  }
  .pt-5 {
    padding-top: 3rem;
  }

  .p-5 {
    padding: 3rem;
  }
  .border-radius-14px {
    border-radius: 14px;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-grey {
    background: #f5f5f7 !important;
  }
  .text-white {
    color: #fff !important;
  }
  .text-primary {
    color: #1764d8;
  }

  .bg-primary {
    background-color: #1764d8 !important;
  }

  .first_section {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .section_2-3 {
    flex-basis: 66.66%;
  }

  .section_1-3 {
    flex-basis: 33.33%;
  }

  .first_section_text_div {
    padding: 100px 150px 100px 200px;
    position: relative;
  }

  .first_section_illustration_div {
    height: 100%;
  }

  .img-center {
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .heading_left_horizontal {
    position: absolute;
    content: "";
    width: 129px;
    height: 39px;
    left: 0;
    background: #ffde9d;
    border-radius: 0px 10px 10px 0px;
  }

  .heading_left_vertical {
    position: absolute;
    content: "";
    height: 129px;
    width: 39px;
    left: 0;
    top: 0px;
    background: #ffde9d;
    border-radius: 20px 4px 4px 4px;
  }

  .d-flex {
    display: flex;
  }

  .d-block {
    display: block;
  }

  .section1-2 {
    flex-basis: 50%;
  }
  .w-100 {
    width: 100%;
  }

  .w-50 {
    width: 50%;
  }

  .h-100 {
    height: 100%;
  }

  .align-items-center {
    align-items: center;
  }

  .p-120px {
    padding: 120px;
  }
  .pb2-120px{
    padding: 50px;
    padding-bottom: 292px;
  }

  .pb-120px{
    padding: 120px;
    padding-bottom: 322px;
  }
  .p-80px{
    padding: 30px;  
  }

  .expertisePara{
    font: 400 18px/30px 'Poppins';
    font-size: 16px;
    line-height: 26px;
    color: #506175;
  }

  .pt-120px{
    padding: 120px;
    padding-bottom: 92px;
  }
  .px-200px {
    padding-left: 200px;
    padding-right: 200px;
  }
  .px-120px {
    padding-left: 7.6%;
    padding-right: 7.6%;
  }
  .p-50px {
    padding: 50px;
  }

  .pt-2 {
    padding-top: 1rem;
  }

  .p-relative {
    position: relative;
  }

  .bg-yellow {
    background: #ebcf76;
  }
  .bg-green {
    background: #46d082;
  }

  .w-25 {
    width: 25%;
  }

  .w-35 {
    width: 35%;
  }
  .w-60 {
    width: 60%;
  }

  .float-end {
    float: right;
  }

  .employee_onboarding__text {
    padding: 10.41%;
    padding-left: 13.8%;
  }

  .employee_onboarding__div {
    background: #fffaed;
    position: relative;
    border-radius: 20px;
  }

  .employee_onboarding__div::after {
    position: absolute;
    content: "";
    width: 154px;
    height: 35px;
    left: 0px;
    top: 0px;
    background: #ffeab8;
    border-radius: 13.6215px;
  }

  .rectangle_type_3 {
    position: absolute;
    width: 53px;
    height: 137px;
    left: 0px;
    top: 0px;
    background: #ffde9d;
    border-radius: 4.17808px;
  }

  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 90px;
    right: 16px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }
  .my-float {
    margin-top: 16px;
  }

  .min-h-320px {
    min-height: 360px;
  }

  .h-30px {
    height: 30px;
  }
  .pb-3 {
    padding-bottom: 1.5rem;
  }
  .p-33px {
    padding: 33px;
  }
  .px-33px {
    margin-left: 33px;
    margin-right: 33px;
  }
  .font-size-107px {
    font-size: 107px;
  }
  .bg-orange {
    background-color: #e5794c;
  }
  .p-4 {
    padding: 1.5rem;
  }

  .bg-light-brown {
    background: #fff4ec;
  }

  .border-radius-20 {
    border-radius: 20px;
  }

  .px-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .mt-50px {
    margin-top: 3.47%;
  }
  .mt-100px {
    margin-top: 6.9%;
  }

  .mx-50px {
    margin-left: 3.47%;
    margin-right: 3.47%;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .w-80 {
    width: 80%;
  }
  .w-90 {
    width: 90%;
  }

  .color-light-blue {
    color: #5cbfd2;
  }

  .task_management_ul {
    list-style: none;
  }

  .task_management_ul li {
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid #b6b6b6;
    font-weight: 600;
    font-size: 20px;
    color: #696969;
    transition: all 0.2s linear;
    position: relative;
  }

  .task_management_ul li:hover {
    /* color: #5cbfd2; */
    border-bottom: 1px solid #5cbfd2;
  }

  .task_management_ul li:hover:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 18px;
    left: -50px;
    top: 0px;
    background: #96e0e5;
    border-radius: 10px 0px 0px 10px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .color-light-grey {
    color: #7b7b7b;
  }

  .color-pink {
    color: #d44161;
  }

  .bg-pink {
    background-color: #f37d92 !important;
  }

  .color-green {
    color: #46d082;
  }

  .blog-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2.8%;
    row-gap: 60px;
  }

  .blog_page_banner {
    min-height: 70vh;
    position: relative;
  }
  .blog_page_banner div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    max-width: 60%;
  }

  .blog_page_banner h1 {
    color: white;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
  }

  .pricing_banner_section {
    min-height: 200px;
    position: relative;
  }

  .princing_banner_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  .princing_banner_text h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 12px;
    color: #1d1d1f;
  }
  .h-auto {
    height: auto !important;
  }
  .princing_banner_text p {
    margin-bottom: 70px;
    font-weight: 300;
    font-size: 36px;
    line-height: 50px;
    color: #7b7b7b;
  }
  .align-items-stretch {
    align-items: stretch;
  }
  .justify-content-center {
    justify-content: center;
  }

  .about_us_section {
    background-color: #1764d8;
    position: relative;
    min-height: 500px;
  }
  .about_us_section_text {
    text-align: center;
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }

  .about_us_section_text h1 {
    color: #fff;
    margin-bottom: 40px;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
  }

  .about_us_section_text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  .about_us_product_card {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 64px;
    padding-bottom: 70px;
  }

  .about_us_product_card_div {
    grid-row-gap: 60px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 60px;
  }
  .about_us_product_card a {
    padding-bottom: 5px;
    border-bottom: 1px solid #7b7b7b;
  }

  .section_our_team_div {
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 60px;
    column-gap: 30px;
  }

  .super_charge_payroll_home_card_div {
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    -webkit-column-gap: 50px;
    column-gap: 50px;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 50px;
  }




  .super_charge_payroll_home_card_div23 {
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    -webkit-column-gap: 50px;
    column-gap: 50px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 50px;
  }

  .super_charge_payroll_home_card_div23 div {
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8.78019px);
    background: transparent;
    border-radius: 21.9505px;
    background: rgba(82, 147, 240, 0.5);
  }

  .navbar__div__link_dropdown_icon213 {
    position: absolute;
    /* left: 113px; */
    /* line-height: 25px; */
    margin-top: 10px;
    margin-left: -30px;
    color: #038ee2;
  }
  .navbar__div__link_dropdown_icon2133 {
    position: absolute;
    /* left: 113px; */
    /* line-height: 25px; */
    /* margin-top: 10px; */

    margin-top: 5px;
  }

  .footerli{
    margin-left: 35px;
  }
  .footerHeading{
    color: #fff;
    font-size: 24px;
      line-height: 33px;
      font-weight: 700;
  }
  .footer_ul .footerli:hover{
    background-color: #1764d8;
  }
  .footerHeading:hover{
  color: #00ADE9;
  }
  .footerlihover span:hover{
    color: #00ADE9;
    text-decoration: none;
  }

  .cardimage:hover{
    background: linear-gradient(0deg, rgba(35, 66, 150, 0.9) 0.38%, rgba(1, 178, 238, 0.9) 100.76%);
      height: 100%;
  }
  .cardHeading{
    color: #fff !important;
      font: 700 16px/26px 'Poppins';
  }
  .cardpara{
    color: #038ee2 !important;
    font: 700 14px/20px 'Poppins';
  }
.footerdibnmain{
  position: relative;
    overflow: hidden;
    min-height: 540px;
}
  .cardimage{
    padding-top: 330px !important;
  }
  /* .copyright{
    border: 2px solid #69899e;
    border-left: none;
    border-right: none;
    padding: 27px 0;
    margin-top: 64px;
    
  } */

  .copyrightP{
    padding-right: 12px;
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    text-align: end;
  }
  .btnTestimonials{
    padding: 14px 30px;
      display: inline-block;
      color: #fff;
      border-radius: 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      background: #000;
      border: none;
      margin-top: 20px;
      border-radius: 5px;
      text-transform: uppercase;
  }
  .btnMessage{
    padding: 14px 30px;
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    background: #000;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
  }
  .p-10px{
    padding: 10px 120px 10px 10px;
  }
  .p-2p{
    padding: 30px;
    text-align: center;
  }
  .super_charge_payroll_home_card_div div {
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8.78019px);
    background: transparent;
    border-radius: 21.9505px;
  }
  .d-inline-block {
    display: inline-block;
  }
  .color-purple {
    color: #7d5ce4;
  }
  .d-grid-50-50 {
    /* grid-column-gap: 30px; */
      /* grid-row-gap: 60px; */
      /* -webkit-column-gap: 30px; */
      /* column-gap: 30px; */
      display: grid;
      row-gap: 60px;
      grid-template-columns: calc(50% - 15px) calc(50% - -15px);
  }
  .d-grid-40-60 {
    display: grid;
    grid-template-columns: calc(40% - 15px) calc(60% - 15px);
    column-gap: 30px;
    row-gap: 60px;
  }
  .d-grid-33-66 {
    grid-row-gap: 60px;
      grid-column-gap: 30px;
      -webkit-column-gap: 30px;
      column-gap: 30px;
      display: grid;
      grid-template-columns: calc(33.33% - 15px) calc(66.66% - 15px);
      row-gap: 60px;
  }
  .d-grid-66-33 {
    display: grid;
    grid-template-columns: calc(66.66% - 15px) calc(33.33% - 15px);
    row-gap: 60px;
    column-gap: 30px;
  }

  .d-grid-33-33-33 {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 60px;
    column-gap: 30px;
  }
  .d-grid-25-25-25-25 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 30px;
  }
  .text-bold {
    font-weight: 600;
  }
  #contact_sales_form,
  #contact_partner_form {
    margin-top: 65px;
  }

  #contact_sales_form button,
  #contact_partner_form button {
    margin-top: 48px;
  }
  #contact_sales_form input,
  #contact_partner_form input {
    margin-bottom: 48px;
    margin-right: 60px;
    border: 0px;
    border-bottom: 1px solid #7b7b7b;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 20px;    height: 60px;
    background: #fff;
    border: none;
    border-radius: 5px;
    outline: none;
    -webkit-appearance: none;
    display: inline-block;
    font: 700 16px/24px "Poppins",sans-serif;
    color: #506175;
  }
  .formInput{
    width: 100%;
      height: 60px;
      background: #fff;
      border: none;
      border-radius: 5px;
      outline: none;
      -webkit-appearance: none;
      display: inline-block;
      font: 700 16px/24px "Poppins",sans-serif;
      color: #506175;
      padding: 0 25px;
  }
  .labelemail{
    font: 500 20px/26px "Poppins",sans-serif;
    color: #fff;
  }
  .formcolor{
    background-image: linear-gradient(95deg, rgb(35, 66, 150) 0.13%, rgb(1, 178, 238) 99.7%);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .divSty{
    margin-top: 30px !important;
  }
  .textformaraea{
    height: 209px;
    resize: none;
    padding: 25px;
  }
  .formH2{
    padding: 50px 0px;
    color: #fff;
    font: 400 34px/40px 'Poppins';
  }
  #contact_sales_form input::placeholder,
  #contact_partner_form input::placeholder {
    color: #b9b9b9;
  }
  #contact_sales_form input:focus,
  #contact_partner_form input:focus {
    border-bottom: 1px solid #7b7b7b;
    outline: none;
  }
  .home_page_banner_text {
    min-height: 500px;
    background-color: #fff;
    position: relative;
    z-index: -1;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .home_page_banner_text_div {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .home_page_banner_text h1 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    margin-bottom: 32px;
  }

  .home_page_banner_text h1 span {
    font-family: Inter;
    font-size: 64px;
    font-weight: 600;
    line-height: 77px;
    letter-spacing: 0em;
  }

  .why_us_home_icons {
    padding: 5px;
    color: white;
    background-color: #1764d8;
    border-radius: 5px;
    font-size: 35px;
  }

  .home_page_banner_text h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .client_logo_slider .swiper-wrapper .swiper-slider {
    height: auto !important;
    border: 1.6495px solid #d5d5d5 !important;
  }
  .Approachcard:hover .innovationIcon {
      color: #fff; 
  }

  .policysH4{
    color: #038EE2 !important;
  }

  .innovationIcon{
    font-size: 100px;
      margin: 20px;
      color: #1d1d1f52;
  }

  .innovationIcons{
    font-size: 100px;
      margin: 20px;
      color: #000;
  }
  .home_page_banner_image {
    margin-left: 165px;
    margin-right: 165px;
    margin-top: -150px;
  }
  .home_page_banner_image img {
    width: 100%;
    z-index: 999999000;
  }
  .super_charge_payroll_home_card {
    padding: 140px 200px;
  }

  .hover-brown-color:hover {
    color: #e5794c !important;
  }
  .hover-yellow-color:hover {
    color: #ecc054 !important;
  }
  .hover-light-blue-color:hover {
    color: #5cbfd2 !important;
  }
  .hover-pink-color:hover {
    color: #d44161 !important;
  }
  .hover-green-color:hover {
    color: #46d082 !important;
  }
  .hover-grey-color:hover {
    color: #696969 !important;
  }

  .hover-blue-color:hover {
    color: #1764d8 !important;
  }

  .hover-brown:hover {
    background-color: #e5794c;
  }
  .hover-yellow:hover {
    background-color: #ecc054;
  }
  .hover-light-blue:hover {
    background-color: #5cbfd2;
  }
  .hover-pink:hover {
    background-color: #d44161;
  }
  .hover-green:hover {
    background-color: #46d082;
  }
  .hover-grey:hover {
    background-color: #696969;
  }
  .swiper-slide {
    height: auto !important;
  }
  .fontColorOwlTop{
    margin-top: -20px;
  }
  .fontColorOwl{
    font: 400 18px/30px 'Poppins';
    color: #B1D3FB;
      font-size: 16px;
      line-height: 26px;
  }
  .swiper-wrapper {
    align-items: center !important;
  }

  .h-58px {
    height: 58px;
  }
  .h-28px {
    height: 28px;
  }

  .max-w-1600px {
    max-width: 1600px;
  }

  .p-100 {
    padding: 100px;
  }

  @media screen and (min-width: 1600px) {
    .blog-grid {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    .max-w-1600px {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .blog-grid {
      display: grid;
      grid-template-columns: auto auto auto auto;
    }
  
  }

  @media screen and (max-width: 992px) {
    .foterPhone{
      padding: 0px 15px !important; 
      max-width: 1200px !important;
    }
    .widgets-inner{
      padding-top: 70px !important;
      padding-left: 0 !important;
    }
    .widgets-inner .shape-2{
      display: none !important;
    }
    .widgets-inner .shape-1 {
      display: none !important;
    }
    .widgets-inner .left-image{
      display: none !important;
    }
    .offer-carousel-box{
      position: relative !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        height: auto !important;
        overflow: hidden !important;
        min-height: 0 !important;
        padding-bottom: 30px !important;
        background: #d5ae82 !important;
        margin-bottom: 50px !important;
    }
    /* .phonerentalBlock {
      margin: 0px !important;
  } */
    .imageLayerabout{
      display: none;
    }
    .contenOuter{
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
    .video-link{
      width: 100% !important;
      height: auto !important;
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      margin: 50px 0 0 !important;
    }
    .video-thumb{
      width: 100% !important;
    height: auto !important;
    }
    .phonefeaturedService{
      margin: 30px 0px !important;
    }
    .aboutSectionTwo .content-box .lower-row .info {
      width: 100%;
    margin-bottom: 20px;
    text-align: left;
    padding-left: 20px;
    padding-right: 0;
    }
    .aboutSectionTwo .content-box .lower-row .info::before {
     
      left: 0;
      right: auto;
  }
.phonefeaturedService{
  margin: 30px 0px;
}

    .d-sm-padding-30px {
      padding: 30px;
  }
  .formDiv{
    margin-top: 0px;
    padding-left: 0px;
  }
  .imgbolg{
    width: 100% !important;
  }

  .paimage{
    padding-top: 20px;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .d-sm-block {
    display: block;
  }
  .py-sm-2rem {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .p-100 {
    padding: 30px!important;
  }
  .d-grid-33-66{
    grid-template-columns: 100%!important;
  }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .w-sm-100 {
    width: 100%;
  }
  .py-sm-2rem {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .w-sm-100 {
    width: 100%;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .d-sm-block {
    display: block;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .py-sm-2rem {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .d-grid-50-50 {
    display: block;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .py-sm-2rem {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .mt-sm-3 {
    margin-top: 1.5rem;
  }
  .d-grid-33-33-33 {
    grid-template-columns: auto;
    padding: 0;
  }
  .first_section_text_div {
    padding: 30px;
    position: relative;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .d-sm-block {
    display: block;
  }
  .img-center {
    left: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .pt-sm-2rem {
    padding-top: 2rem;
  }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .mb-sm-60 {
    margin-bottom: 60px;
  }
  .mx-sm-2rem {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .w-sm-85 {
    width: 85%;
  }
  .w-sm-100 {
    width: 100%;
  }
  .py-sm-2rem {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .w-sm-100 {
    width: 100%;
  }
  .d-grid-66-33 {
    grid-template-columns: 100%;
    margin-bottom: 10%;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .mt-sm-60px {
    margin-top: 60px;
  }
  .padding_mobile_1rem {
    padding: 1rem;
  }
  .leavemng_tna {
    display: block;
  }
  .mt-sm-130px {
    margin-top: 130px;
  }
  .button_home_primary {
    margin-bottom: 20px;
    min-width: 305px;
  }
  .button_home_alternative {
    margin: 0;
    min-width: 305px;
  }
  .home_page_banner_image {
    margin-left: 20px;
    margin-right: 20px;
  }
  .super_charge_payroll_home_card_div div {
    padding: 30px;
  }
  .super_charge_payroll_home_card, .super_charge_payroll_small_card {
    padding: 30px;
  }
  .super_charge_payroll_home_card_div {
    grid-template: none;
  }
  .d-grid-25-25-25-25, .d-grid-33-66 {
    grid-template-columns: 100%;
  }
  .d-grid-25-25-25-25 {
    padding: 30px;
    row-gap: 30px;
  }
  .text-sm-center {
    text-align: center;
  }
  .testimonialssty{
    padding: 0px !important;
  }
  .powering_teams {
    padding: 30px;
  }
  .home_page_banner_text_div h1 {
    justify-content: center;
  }
  .p-0px{
    padding: 0px;
  }
  .home_page_banner_image video
  {
    margin: 125px 0px 0px 0px !important;
    width: 100% !important;

  }
  }
  .btnTestimonials:hover{
    background: #ebfaff;
    color: #000 !important;
  }
  .btnMessage:hover{
    background: #ebfaff;
    color: #000 !important;
  }
  .recruitmentPara{
    color: #EBFAFF;
    font-size: 16px;
    line-height: 26px;
  }
  .pRecruitment-120px{
    background-color: #000;
    color: #fff;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    /* padding-bottom: 232px; */
  }
  .blogpara{
    color: #506175;
      padding: 0px;
      margin: 0px;
      font: 400 18px/30px 'Poppins';
      padding-bottom: 20px;
  }
  .bloghead{
    padding: 0px;
      margin: 0px;
      font: 500 20px/28px 'Poppins';
      padding-bottom: 20px;
      color: #00ADE9;
  }
  .news_link{
    position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;
  }
  .pOur-120px{
    padding-top: 50px;
    padding-right: 100px;
    padding-left: 100px;
    /* padding-bottom: 45px; */
  }
  .Local{
    height: 100%;
    background-color: #000;
  }



  .custom-nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #000; /* Adjust the color as needed */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .custom-nav-btn-left {
    left: 10px;
  }

  .custom-nav-btn-right {
    right: 10px;
  }











  .formp{
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    padding-top: 30px;
    text-align: left;
    float: left;
    font-family: "Poppins",sans-serif;
    font-weight: 700;
    padding-top: 18px;
  }
  .spanPrivacy{
    color: #000;
    text-decoration: none;
  }
  .spanPrivacy:hover{
    color: #fff;
    text-decoration: none;
  }
  .Approachcard{
    border: 2px solid rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(8.78019px);
      backdrop-filter: blur(8.78019px);
      background: transparent;
      border-radius: 10px;
      background: rgba(82, 147, 240, 0.5);
      text-align: center;
      width: 300px !important;
      margin-left: 20px;
      margin-top: 20px !important;
  }
  .btnTestimonials a{
    text-decoration: none;
    color: #fff;
  }
  .btnTestimonials a:hover{
    
    color: #000;
  }
  .btnMessage a{
    text-decoration: none;
    color: #fff;
  }
  .OurPledge{
    font-size: 20px;
      line-height: 31px;
      color: #506175;
  }
  .preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0,0,0,0);
}
 /* .icon {
  opacity: 0;
} */




.preloader .icon {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0,0,0,0);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.preloader.loaded::before {
  transform: translateX(-101%);
}
 .preloader::before {
  /* -webkit-transform: translateX(-101%); */
  /* -ms-transform: translateX(-101%); */
  /* transform: translateX(-101%); */
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 51%;
  height: 100%;
  background: #ffffff;
  z-index: 0;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 800ms ease;
  -moz-transition: all 800ms ease;
  -ms-transition: all 800ms ease;
  -o-transition: all 800ms ease;
  transition: all 800ms ease;
}
.preloader::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 51%;
  height: 100%;
  background: #ffffff;
  z-index: 2;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 800ms ease;
  -moz-transition: all 800ms ease;
  -ms-transition: all 800ms ease;
  -o-transition: all 800ms ease;
  transition: all 800ms ease;
}
.offer-block .icon-box {
  position: relative;
  display: block;
  font-size: 90px;
  line-height: 1em;
  margin-bottom: 20px;
}

  @media screen and (max-width: 768px){
   .footer-bottom .copyright {
      width: 100% !important;
      text-align: center !important;
  }
  .footer-bottom .bottom-links{
    width: 100% !important;
    text-align: center !important;
}
  .footer-bottom .inner {
    padding-left: 0 !important;
}
.foterPhone{
  padding: 0px 15px !important; 
  max-width: 1200px !important;
}
.widgets-inner{
  padding-top: 70px !important;
  padding-left: 0 !important;
}
.widgets-inner .shape-2{
  display: none !important;
}
.widgets-inner .shape-1 {
  display: none !important;
}
.widgets-inner .left-image{
  display: none !important;
}
.offer-carousel-box{
  position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: auto !important;
    overflow: hidden !important;
    min-height: 0 !important;
    padding-bottom: 30px !important;
    background: #d5ae82 !important;
    margin-bottom: 50px !important;
}
    .left-column{
      width: 100% !important;
    }
    .left-layer{
      display: block !important; 
    }
    .inner{
      padding: 0 20px !important;
    max-width: none !important;
    }
    .content{
      padding-top: 120px !important;
    }
    .bckIMg .image-layer::before {
      transform: none;
      right: 0;
      left: auto;
  }
  .right-column{
    width: 100% !important;
  }
    .imageLayerabout{
      display: none;
    }
    .contenOuter{
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
    .video-link{
      width: 100% !important;
      height: auto !important;
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      margin: 50px 0 0 !important;
    }
    .video-thumb{
      width: 100% !important;
    height: auto !important;
    }
    .phonefeaturedService{
      margin: 30px 0px !important;
    }
    .aboutSectionTwo .content-box .lower-row .info {
      width: 100%;
    margin-bottom: 20px;
    text-align: left;
    padding-left: 20px;
    padding-right: 0;
    }
    .aboutSectionTwo .content-box .lower-row .info::before {
     
      left: 0;
      right: auto;
  }
    .phonerentalBlock{
      margin: 50px 0px !important;
    }
    
.owlPhoneBtn{
  position: relative;
    width: 100%;
    padding-bottom: 40px;
    text-align: center;
    margin: 0px 7px;
}
/* sailing */
.M0px{
  margin: 0px !important;
}
.w100{
  width: 100%;
}

.M30{
  margin-top: 30px !important;
}
/* sailing */

.bestCarousel{
  padding-left: 0;
    max-width: 480px;
    margin: 0 auto;
    height: 810px;
}
.p0px{
  padding: 0px;
}
.upperRow{
  position: relative;
  padding-left: 0px;
  min-height: 232px;
  text-align: center;

}
.formDiv{
  margin-top: 0px;
  padding-left: 0px;
}
.imageboxAbout{
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
  margin: 0 auto 10px;
}
.ratediv{
  top: -26px;
}
.contentBoxSkiing h1{
  font-size: 40px;
}
.textFreedom{
  text-align: center;
}
.M20px{
  margin-top: 20px;
}
.btnFloat{
  float: left;
}
.imgCarousel{
  position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}








  }



  /* fdsfds */



  .banner_inner{
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9;
  }

  .whyhading{
    color: rgb(255, 255, 255);
    padding: 0px;
    margin: 0px;
    font: 400 50px/60px 'Poppins';
    padding-bottom: 20px;
  }

  /* .inner_ban  ::before {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    height: 100%;
    background: rgba(35, 34, 37, 0.30);
    content: "";
    z-index: 5;
  } */
  .blog_textdate {
    font-size: 14px;
    line-height: 20px;
    color: #00ADE9;
    font-weight: 700;
  }

  .inner_ban{
    position: relative;
    height: 349px;
  }