.navbar__hamburger,
.navbar__mobile {
  display: none;
}

.navbar__div {
  align-items: center;
    background-color: #fff;
    color: #000;
    display: flex;
    max-height: 121px;
    padding: 1rem 7rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar__div__logo img {
  height: 50px;
  
}

.navbar__div__links {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* .navbar__div__links ul li a:hover{
  color: #1764d8;

} */


/* .navbar__div__links_ul li a {
  position: relative;
  other styles...
} */

/* .navbar__div__links_ul li a:before {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 10px;
    width: 10px;
    height: 5px;
    background: #54CDFA !important;
    visibility: hidden;
    transition: all 0.3s;
    opacity: 0;
} */

/* .navbar__div__links_ul li a:hover::before {
  visibility: visible;
  opacity: 1;
  Add other styles as needed for the hover state
} */

.navbar__div__links_ul li a:hover {
 color: #d5ae82 !important;
}

.navbar__div__links_ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: 0px;
  margin-bottom: 0px;
}
.navbar__div__link {
  margin-left: 25px;

  line-height: 20px;
  font-family: 'Poppins';
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #25283a;
  text-transform: capitalize;
}
.navbar__div__link_sub {
  /* margin-left: 25px; */

  line-height: 20px;
  font-family: 'Poppins';
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #25283a;
  text-transform: capitalize;
}


.navbar__div__link a{
  text-decoration: none;
  color: #000
}
.nav_barlink_dropdown {
  position: relative;
  text-decoration: none;
  color: #000;
}

.navbar__div__link_dropdown_icon {
  position: absolute;
  left: 30px;
  line-height: 25px;
  margin-top: 10px;
  color: #26a8ff !important;
}

.navbar__div__link_dropdown_iconMob {
  position: absolute;
  text-align: center;
}
.navbar__div__link_dropdown_iconMob1{
  position: absolute;
  margin-left: -24px;
  color: #000;
}

.navbar__div__link_dropdown_icon23 {
  position: absolute;
 
  line-height: 25px;
  margin-left: 45px;
}

.btnREGION{
  padding: 8px;
  padding-right: 70px;
  padding-left: 15px;
  border-radius: 23px;
  width: 130px;
  /* width: 150px; */
  font-size: 20px;
  font-weight: 700;
  background: #f5f5f6;
  border-radius: 30px;
  font-size: 14px;
}

.btnREGIONuk{
  padding: 7px;
  border-radius: 50px;
  width: 130px;
  margin-left: -30px;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}

.product_text ul li a:hover{
  color:#d5ae82 !important
}
.product_text ul  li:hover  a::before {
  background: #d5ae82;
}
.product_text ul li a{
  position: relative;
    display: block;
    padding: 12px 0px 12px 20px;
    line-height: 24px;
    font-weight: 600;
    font-size: 13px;
    color: #868da5;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
}

.product_text ul li a::before{
  content: '';
  position: absolute;
  left: 0;
  top: 22px;
  height: 4px;
  width: 4px;
  background: #868da5;
}
.product_text{
  color: #fff;
  text-decoration: none;
}

.product_text2 ul li a:hover{
  color: #038EE2 !important
}


.navbar__div__links_buttons button {
  background: transparent;
  color: #ffffff;
  margin: 0 10px;
  border: 0px;
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
}

.navbar__div__links_buttons button:last-child {
  background-color: #fff;
  color: #1764d8;
  padding: 10px 40px;
  border-radius: 20px;
}
.navbar__div__link__dropdown_div {
  position: absolute;
  display: flex;
  background-color: #fff;
  padding: 1rem 2rem;
  left: 0;
  /* width: 100%; */
  top: 54px;
  box-shadow: 0px 0px 3px 0 #00000059;
  z-index: 99999;
  color: #000;
}

.navbar__div__link__dropdown_div__items {
  display: flex;
}
.navbar__div__link__dropdown_div__items > ul {
  list-style: none;
}
.navbar__div__link__dropdown_div__items ul li {
  width: auto;
}

.productDropdown {
  position: absolute;
  top: 82px;
  /* left: 0px; */
  /* width: 100%; */
  background: #061138;
  /* border-radius: 10px; */
}


.productDropdownsub {
  position: absolute;
  top: 170px;
  right: 254px;
  background: #061138;
}

.productDropdownsubPage {
  position: absolute;
  top: 120px;
  right: 254px;
  background: #061138;
}

.sublist::after{
  font-family: 'Font Awesome 5 Free';
  content: ">";
  position: absolute;
  right: 0px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.navbar_backdrop{
  position: absolute;
  top: 100px;
  left: 0px;
  padding: 100px;
  width: 100%;
  background-color: #f5f5f7;
  height: 100vh;
  opacity: -0;
}

.single_product_desc{
  display: flex;
  color: #000;
}

.product_icon{
  padding: 10px 20px;
  font-size: 40px;
}

.product_text {
  color: #7f8a99 !important;
}
.product_text2 {
  color: #000000 !important;
}
.product_text h4 {
  color: #7B7B7B !important;
}
.slide_from_top {
  animation: slide_from_topAn 2s ease-in-out;
  display: block;
  height: 100vh!important;
}
@media screen and (max-width: 768px) {
.navbar__div {
  position: sticky;
  top: 0px;
  background-color: #fff;
  color: #000;
  display: flex;
  padding: 30px;
  align-items: center;
  align-content: space-around;
  max-height: 121px;
  z-index: 999;
}
.navbar__div__logo img {
  height: 40px;
}
.navbar__mobile {
  background: #26a8ff;
  display: block;
  height: 0;
  /* left: 0; */
  overflow: hidden;
  padding: 0 2rem;
  position: absolute;
  top: 75px !important;
  transition: .5s ease;
  width: 100%;
  z-index: 9999;
  color: #fff;
}
.navbar__div__link a{
  position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    
}
}
.marLiMoblie{
  font-size: 14px;
    margin-left: 20px;
    text-transform: capitalize;
}


@media screen and (max-width: 1304px) {
  .navbar__hamburger {
    display: block;
    margin-left: auto;
    font-size: 20px;
  }
  .navbar__div__links {
    display: none;
  }
  /* .navbar__div ul li  a::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    border-left: 3px solid #d5ae82;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    height: 100%;
  } */
  .navbar__mobile {
    background: #212529;
    display: block;
    height: 0;
    
    overflow: hidden;
    padding: 0 2rem;
    position: absolute;
    top: 80px;
    transition: .5s ease;
    width: 100%;
    z-index: 9999;
    color: #fff;
    right: 0;
    width: 350px;
    padding-right: 30px;
    max-width: 100%;
  }

  .navbar__mobile ul {
    list-style: none;
  }
  .navbar__div ul li {
    /* margin: 20px 0; */
    position: relative;
    display: block;
    border-top: 1px solid rgba(255,255,255,0.10);
  }
}
.moble_bor:last-child {
  border-bottom: 1px solid rgba(255,255,255,0.10);
}

.navbar__div__link_dropdown_iconMob{
  position: absolute;
    right: 10px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    background: rgba(255,255,255,0.10);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}
.borderRadiusNavbarDropdown{
     /* border-radius: 0 20px 20px 0; */
    /* padding: 41px; */
    /* padding-top: 20px; */
    /* padding-right: 40px;
    padding-left: 30px; */
    /* padding-bottom: 32px; */
   
    padding: 0px 30px;
   
    line-height: 40px;
    width: 250px;
    font-weight: 600;
    font-size: 18px;
    
}

.borderRadiusNavbarDropdownUp{
  /* border-radius: 0 20px 20px 0; */
 /* padding: 41px; */
 /* padding-top: 20px; */
 padding-right: 40px;
 padding-left: 30px;
 /* padding-bottom: 32px; */
 line-height: 40px;
 width: 130px;
 font-weight: 600;
 font-size: 18px;
}

.slide_from_top {
  -webkit-animation: slide_from_topAn 2s ease-in-out;
  animation: slide_from_topAn 2s ease-in-out;
  display: block;
  height: auto!important;
}